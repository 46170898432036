.about-page {
  display: flex;
  flex-direction: column;
  margin: 5%;
}

.about-section{
  display: flex;
  flex-direction: row;
  width: 100%; 
}

.about-right, 
.about-left {
  width: 40%; 
  margin: 5%;
}

.about-left {
  order: 2;
}
.about-left {
  order: 1;
}

.about-section h2 {
  text-align: center; 
  font-size: 40px;
}

.about-section img {
  border-radius: 50%;
  background-color: #ccc;
}

.about-section p {
  font-size: 20px;
  text-align: justify; 

  background-color: #ccc;
  padding: 10%;
  border-radius: 50px;
}

.highlight {
  color: #4769ff; 
}

@media screen and (max-width: 1500px){
  .about-section{
    flex-direction: column;
    width: 100%; 
  }

  .about-left {
    order: 0;
  }
  .about-left {
    order: 0;
  }

  .about-right, 
  .about-left {
    width: 100%;
    margin: 0 auto;
  }

  .about-section img {
    width: 50%;
  }
  .about-section p{
    padding: 5%;
  }
}


@media screen and (max-width: 1000px){
  .about-section{
    flex-direction: column;
    width: 100%; 
  }

  .about-left {
    order: 0;
  }
  .about-left {
    order: 0;
  }
  
  .about-right, 
  .about-left {
    width: 100%;
    margin: 0 auto;
  }
  
  .about-section img {
    width: 75%;
  }
  
  .about-section p {
    padding: 5%;
  }
  
  .highlight {
    color: #4769ff; 
  }
}