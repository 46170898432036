.skills {
    z-index: 0;
    padding: 20px;
  }
  
  .timeline {
    margin-top: 20px;
  }

chrono {
    padding: 200px; /* Add padding inside the item */
    margin-bottom: 20px; /* Add space between items */
    border-radius: 8px; /* Optional: Add border radius for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visual separation */
    background-color: #fff; /* Background color for better contrast */
    border: 1px solid #ddd; /* Optional: Add border */
}