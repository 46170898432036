.header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px;
    color: white;
  }
  
  .menu-button {
    position: fixed;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    margin-top: 5ch;
    border-radius: 50%;
    z-index: 9999; 
  }
  
  .menu-dock {
    position: fixed;
    top: 0;
    left: -50%;
    width: 25%;
    height: 100%;
    background-color: #444;
    padding: 0px;
    transition: left 0.3s ease;
    z-index: 10000;
  }
  @media screen and (max-width: 1000px){
    .menu-dock {
      left: -100%;
      width: 50%;
    }
    a{
      font-size: 15px;
    }
  }
  @media screen and (max-width: 600px){
    a{
      font-size: 8px;
    }
  }

  .menu-dock.open {
    left: 0;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    color: white;
  }
  
.menu li {
    width: 100%;
    border-bottom: 1px solid #555;
    border-top: 1px solid #555;
}
  
  .menu li a {
    display: block;
    padding: 20px 0px;
    text-decoration: none;
    border-radius: 4px;
    text-align: center;
  }
  
  .menu li a:hover {
    background-color: #666;
  }
  
  .menu li a:active {
    background-color: #777;
  }


  