/* src/App.css */
body {
  background-image: url('../public/images/bg-modified.jpg');
  background-size: cover; /* Ensure the image covers the whole background */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-attachment: fixed; /* Make the background fixed */
  background-position: center; /* Center the background image */
  margin: 0; /* Remove default margin */
  height: 100vh; /* Ensure the body takes the full viewport height */
}

text {
  font-family: 'Baskerville', serif;
}
h2 {
  font-family: 'Copperplate';
}
h1 {
  font-family: 'Copperplate';
}

body::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5); /* Black color with 50% opacity */
            z-index: -1; /* Ensure the overlay is behind the content but above the background image */
            filter: blur(50px);
        }

img{
  max-width: 100%;
  height: auto;
  border-radius: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: none;
  color: white;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;
  padding: 9%;
}

.grid img {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s;
}

.column:hover {
  transform: scale(1.05);
}

.column {
  margin: 10px;
  padding: 30px;
  border-radius: 20px;
  background-color: #ccc;
}

a {
  text-decoration: none;
  color: inherit; /* Inherit color from parent element */
}

a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

.column-title {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  text-decoration: none;
  color: black;
}

.column-text {
  font-size: 16px;
}
.contact {
  padding: 20px;
  color: white;
}

.contact h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-decoration: none;
}

.contact-item {
  margin-bottom: 15px;
  display: flex;
  align-items: center; /* Align items vertically */
}

.contact-item img {
  width: 3em; /* Adjust the width of the icon */
  height: auto;
  margin-right: 10px; /* Add spacing between icon and text */
}

.contact-item label {
  font-weight: bold;
}

.contact-item a {
  text-decoration: none;
  color: #999999;
}

.contact-item img.contact-picture {
  width: 200px;
  height: auto;
  display: block;
  margin-top: 10px;
}

.contact-item a:hover {
  text-decoration: underline;
}


/* Scrollbar.css */

/* Track */
::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.25); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5); /* color of the scroll thumb on hover */
}

/* Track */
::-webkit-scrollbar-track {
  background: #333; /* color of the track */
}

/* Handle when scrollbar is near the top/bottom */
::-webkit-scrollbar-thumb:vertical {
  min-height: 50px; /* height of the thumb */
}
