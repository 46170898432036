.page {
  display: flex;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 5%;
}

.project-left {
  width: 50%;
}

.project-right {
  width: 50%;
  padding: 20px;
  margin-left: 10%;
  margin-bottom: 20px;

  background-color: #ccc;
  border-radius: 20px;
  
}

.project-right h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

img {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 20%;
}

@media screen and (max-width: 1000px){
  .page {
    display: flex;
    flex-direction: column;
  }

  .project-left,
  .project-right{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

}